function getFloatPos(pathData, i){
    var firstStart = i
    while (pathData[i] != " " && pathData[i] != 'Z' && pathData[i] != '\n' && pathData[i] != ',' && i < pathData.length){
        i = i+1
    }
    var firstEnd = i
    return [firstStart, firstEnd, i]
}

function getExtentsFromSvgText(text, mapHeight){

    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(text, 'image/svg+xml');
    const pathElement = svgDoc.querySelector('path');

    // Get the 'd' attribute value (the path data)
    const pathData = pathElement.getAttribute('d');
    var extents = []
    var currentPolygonPoints = []
    var i = 0
    while (i < pathData.length){
        const currentChar = pathData[i];
        if (currentChar == 'Z'){
            extents.push(currentPolygonPoints)
            currentPolygonPoints = []
        }
        if (currentChar >= '0' && currentChar <= '9'){

            var [start1, end1, i] = getFloatPos(pathData, i)
            i = i + 1
            
            var [start2, end2, i] = getFloatPos(pathData, i)
            var pair = [
                parseFloat(pathData.substring(start1, end1)),
                mapHeight - parseFloat(pathData.substring(start2, end2))         
            ]
            currentPolygonPoints.push(pair)
            
        }
        else{
            i = i + 1
        }
    }
    return extents
}
export function getExtentsFromSvg(svgPath, mapHeight){
    return new Promise((resolve, reject) => {
        fetch(svgPath)
            .then((response) => {
                if (!response.ok){
                    resolve([[]])
                }
                else{
                    response.text().then((text) => {
                        var extents = getExtentsFromSvgText(text, mapHeight)
                        resolve(extents)
                    })
                }
            })
            .catch((reason) => {
                console.log(reason)
                resolve([[]])
            })
    });
}
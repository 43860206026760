import ImageLayer from 'ol/layer/Image.js';
import Map from 'ol/Map.js';
import Projection from 'ol/proj/Projection.js';
import Static from 'ol/source/ImageStatic.js';
import View from 'ol/View.js';
import {getCenter} from 'ol/extent.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import Feature from 'ol/Feature.js';
import Polygon from 'ol/geom/Polygon.js';
import {Control, defaults as defaultControls} from 'ol/control.js';
import {Text, Fill, Stroke, Style, Icon} from 'ol/style.js';
import { getExtentsFromSvg } from "./svg-util"

function getImageLayer(url, projection, size){
  return new ImageLayer({
    source: new Static({
      url: url,
      projection: projection,
      imageExtent: size,
      attributions: ['Maps created by Andrew Jimenez']
    }),
    zIndex: 2
  })
}
function getVectorLayerUsingFeatures(features){
  var style = new Style({
    stroke: new Stroke({
      color: 'black',
      width: 2,
    }),
    fill: new Fill({
      color: 'yellow'
    }),
  })
  return new VectorLayer({
    source: new VectorSource({
      features: features
    }),
    style: style,
    zIndex: 3,
    opacity: 0.3,
  })
}
function getVectorLayer(extents){
    var features = []
    extents.forEach((extent) => {
      var geometry  = new Polygon([
        extent
      ])
      var feature = new Feature({
        geometry: geometry
      })
      features.push(feature)
    })
    return getVectorLayerUsingFeatures(features)

}
class BackButtonControl extends Control{
  constructor(options) {
    var button = document.createElement('button');
    button.innerHTML='&laquo;'
    var element = document.createElement('div')
    element.className = 'back ol-control'
    element.appendChild(button)
    super({
      element: element
    })
  }
}
export default class MapContainer{
    constructor(name, imageExt, size, children){
      /**
       * Container for an OpenLayers map, with some additional fields
       * name - the name of the map
       * url - the image url to use for the map
       * extent - the position of this map on the world (top-most) map [left, bottom, right, top]
       * size - the size of this map [left, bottom, right, top]
       * children - children maps, if any
       */
      this.name = name;
      this.imageExt = imageExt

      this.extents = null
      
      this.size = size
      this.children = children

    }
    initAfterExtent(){
      this.base_layer = getImageLayer(this.getImageUrl(), this.projection, this.size)
      this.layers = [ this.base_layer ]
      if (this.children != null){
        this.children.forEach(child => {
          var layer = getVectorLayer(child.extents)
          child.setVectorLayer(layer);
          this.layers.push(layer)
        })
      }
      this.projection = new Projection({
        code: this.name,
        units: 'pixels',
        extent: this.size
      }) 
      this.map = new Map({
        controls: defaultControls().extend([new BackButtonControl()]),
        layers: this.layers,
        target: 'empty',
        view: new View({
          projection: this.projection,
          center: getCenter(this.size),
          extent: this.size,
          zoom: 0,
        }),
      });
    }
    initChildren(resolveCallback){
      var promises = []
      if (this.children != null){
        this.children.forEach(child => {
          promises.push(child.init(this))
        })
        Promise.all(promises)
          .then(() => {
            this.initAfterExtent()
            resolveCallback()
          })
      }
      else{
        this.initAfterExtent()
        resolveCallback()
      }

    }
    init(parent){
      return new Promise((resolve, reject) => {

        if (this.extents == null){
          getExtentsFromSvg(this.getOutlineUrl(), parent?.size[3] || 0) // size[3] == height
          .then((extents) => {
            this.extents = extents
            this.initChildren(resolve)
          })
          .catch(error => {
            console.log(error)
          })
        }
        else{
          this.initChildren(resolve)
        }  
      });
    }
    getImageUrl(){
      return './' + this.name + '.' + this.imageExt
    }
    getOutlineUrl(){
      return './svg/' + this.name + '.svg'
    }
    getBoundingBoxes(){
      var boxes = []
      this.extents.forEach(extent => {
        var bound = [null,null,null,null]
        extent.forEach(coords => {
          var x = coords[0]
          var y = coords[1]
          if (x < bound[0] || bound[0] == null){
            bound[0] = x
          }
          else if (x > bound[2]){
            bound[2] = x
          }
          if (y < bound[1] || bound[1] == null){
            bound[1] = y
          }
          else if (y > bound[3]){
            bound[3] = y
          }
        })
        boxes.push(bound)
      })

      return boxes
    }
    setVectorLayer(vectorLayer){
      this.vectorLayer = vectorLayer
    }
    scaleVector(){
      var style = this.vectorLayer.getStyle()
      style.setStroke(new Stroke({
        color: 'white',
        width: 10,
      }))
    }
    setViewSize(width, height){
      var adjusted_size = this.size

      // Need to figure out this logic more precisely, but this seems to work for now
      if (width < this.size[2]){
        if (width > height){
          var delta = Math.abs(this.size[2] - width)
          adjusted_size = [adjusted_size[0]-delta, adjusted_size[1], adjusted_size[2] + delta, adjusted_size[3]]
        }
        else {
          var delta = Math.abs(this.size[3] - height)
          adjusted_size = [adjusted_size[0], adjusted_size[1]-delta, adjusted_size[2], adjusted_size[3] + delta]
        }
      }
      else{
        var delta = width - height
        adjusted_size = [adjusted_size[0]-delta, adjusted_size[1], adjusted_size[2] + delta, adjusted_size[3]]
      }
      this.map.setView(new View({
        projection: this.projection,
        center: getCenter(adjusted_size),
        extent: adjusted_size,
        zoom: 0,
      }))
      this.map.getView().adjustZoom(-1)
    }
    enable(){
      this.map.setTarget("map")   
    }
    disable(){
      this.map.setTarget("empty")
    }
  }
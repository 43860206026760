import { ZOOM_BACK_THRESHOLD, ZOOM_BACK_WEIGHTS, WORLD_MAP } from "./data";

// global variables used to track zoom attempts/current & previous map
var current_map = WORLD_MAP
var previous_maps = []
var zoom_back_attempts = 0;

function bind_handlers_to_current_map(){
  /**
   * For the current map, bind handler methods to UI
   */
  current_map.map.on('moveend', map_move_listener)
  current_map.map.on('click', map_click_listener)

  if (current_map != WORLD_MAP){ // zoom handlers not necessary for top level map
    current_map.map.getViewport().addEventListener('wheel', zoom_out_handler)
    // document.getElementById('map').addEventListener('touchstart', zoom_out_handler);
    current_map.map.getViewport().getElementsByClassName('ol-zoom-out')[0].addEventListener('click',zoom_out_handler)
    current_map.map.getViewport().addEventListener('wheel', zoom_in_handler)
    current_map.map.getViewport().getElementsByClassName('ol-zoom-in')[0].addEventListener('click',zoom_in_handler)
    current_map.map.getViewport().getElementsByClassName('back')[0].addEventListener('click',zoom_out_handler)

  }
}

function zoom_in_to_nested_map(child_map){
  /**
   * Swap one map in with another
   */
  current_map.map.getView().adjustZoom(-1.5) // Pull old maps zoom back a bit so it's just before the transition
  
  //push current to stack
  previous_maps.push(current_map)

  //swap 
  current_map.disable()
  child_map.setViewSize(window.innerWidth, window.innerHeight)
  child_map.enable()
  current_map = child_map
  bind_handlers_to_current_map()

}

function zoom_out_of_nested_map(){
  /**
   * Swap one map in with another
   */
  zoom_back_attempts = 0
  
  current_map.disable()
  current_map = previous_maps.pop()
  current_map.enable()

  bind_handlers_to_current_map()
}

var zoom_out_handler = function(event) {
  /**
   * Handles zoom out events, either mouse wheel scrolls or button clicks. Needed to give map transitions appropriate "stickiness"
   */
  // If a reverse scroll, or a click
  if (event.type == 'click' && event.srcElement.parentElement.className.includes('back')){
    zoom_back_attempts = ZOOM_BACK_THRESHOLD + 1
  }
  if (event.deltaY > 0 || event.type == 'click'){
    var view = current_map.map.getView();
    var zoom = view.getZoom();
    var constrained_zoom = view.getConstrainedZoom(0, -1); //min zoom is often constrained, so get the real min zoom using this function
    if (zoom.toFixed(3) >= constrained_zoom.toFixed(3) - 0.05 && zoom.toFixed(3) <= constrained_zoom.toFixed(3) + 0.05) {
      var type = event.type
      if (type == 'wheel' && event.ctrlKey){
        type = 'pinch'
      }
      zoom_back_attempts += ZOOM_BACK_WEIGHTS[type]; //Mouse wheel scrolls will hit this multiple times, so we weigh events differently
    }
  }
  if (zoom_back_attempts > ZOOM_BACK_THRESHOLD && previous_maps.length > 0) {
    zoom_out_of_nested_map()
  }
}

var zoom_in_handler = function(event) {
  /**
   * Handle zoom in events. Really just needed to reset zoom back attempts
   */
  if (event.deltaY < 0 || event.type == 'click'){
    zoom_back_attempts = 0
  }
}

var map_move_listener = function (event) {
  /**
   * Swaps into a child map if within that maps extent
   */
  var view = current_map.map.getView();
  var current_boundary = view.calculateExtent(current_map.map.getSize())
  if (current_map.children != null){
    current_map.children.forEach(child => {
      var boxes = child.getBoundingBoxes()
      boxes.forEach(bound => {
        if (
          current_boundary[0] > bound[0] 
          && current_boundary[2] < bound[2]
          && current_boundary[1] > bound[1]
          && current_boundary[3] < bound[3]
        ){
          zoom_in_to_nested_map(child)
        }
      })

    });
  }
}
var map_click_listener = function (event) {
  /**
   * Swaps into a child map if clicked
   */
  if (current_map.children != null){
    current_map.children.forEach(child => {
      var boxes = child.getBoundingBoxes()
      boxes.forEach(bound => {
        if (
          event.coordinate[0] > bound[0] 
          && event.coordinate[0] < bound[2]
          && event.coordinate[1] > bound[1]
          && event.coordinate[1] < bound[3]
        ){
          zoom_in_to_nested_map(child)
        }
      })

    });
  }
}
WORLD_MAP.init().then(() => {
  WORLD_MAP.enable();
  current_map.map.on('moveend', map_move_listener)
  current_map.map.on('click', map_click_listener)
}).catch(error => {
  console.log(error)
})

